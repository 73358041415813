/* PAGE & CONTAINERS */
body {
	font-family: "Quicksand", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}

a {
	text-decoration-line: none;
	color: #337ab7;
}

a:hover {
	text-decoration-line: underline;
	cursor: pointer;
}

.ps-page-body {
	background-color: transparent;
}

.ps-overflow-body {
	background-color: transparent;
}

.ps-container > h3, .ps-page > h3, .ps-container > div > h3 {
	margin-top: 0em;
	color: var(--text-c4);
	font-size: 1.5em;
}

.ps-page {
	background-color: transparent;
}

fieldset.ps-fieldset {
	border: 0.1em solid var(--bg-primary);
	border-radius: 0em;
	padding: 0.9em;
	margin: 1em 1em 1em 1em;
	min-width: 15%;
	border-left: none;
	border-right: none;
	border-bottom: none;
	position: relative;
	padding-top: 4em;
	background-color: transparent;
}

fieldset.ps-fieldset legend {
	position: absolute;
	top: 0.5em;
	border: none;
	color: var(--text-c1);
	z-index: 1;
	padding: 0em;
}

fieldset[class*="ps-overflow-body"] {
	padding-top: 0;
}

fieldset[class*="ps-overflow-body"] > legend + *,
fieldset[class*="ps-overflow-body"] > legend + * > * {
	margin-top: 4em;
}

data-pagination {
	justify-content: flex-end;
	display: flex;
}


/* PS-TREE */
.ps-tree li {
	color: var(--text-c4);
}

.ps-tree a {
	color: var(--text-c4);
}


/* TABLES */
.ps-table > thead {
	color: var(--text-c3);
}

.ps-table > tbody {
	color: var(--text-c4);
}


/* NAVBAR & SIDEBARS */
.ps-navbar {
	background-color: var(--bg-c1);
}

.ps-nav-links li a.active, .ps-nav-links li label.active {
	background-color: var(--fg-c6);
	color: var(--bg-primary);
	border-radius: 0;
}

.ps-sidebar > ul > li > a, .ps-sidebar > ul > li > div > ul > li > a,
.ps-nav-links-right > li > ul > li > a, .ps-link-container > ul > div > li > a {
	color: var(--text-c4);
	font-size: 1.1em;
}

.ps-sidebar > ul > li:hover, .ps-sidebar > ul > li > div > ul > li:hover,
.ps-nav-links-right > li > ul > li:hover, .ps-link-container > ul > div > li:hover {
	background-color: var(--fg-c6);
	color: var(--bg-primary);
	border-radius: 0;
}

.ps-nav-links li a:hover, .ps-nav-links li label:hover {
	background-color: transparent;
	color: var(--bg-primary);
	border-radius: 0px;
}

.ps-nav-links li:hover > a, .ps-nav-links li label:hover,
.ps-sidebar > ul > li:hover > a, .ps-sidebar > ul > li > div > ul > li:hover > a,
.ps-nav-links-right > li > ul > li:hover > a, .ps-link-container > ul > div > li:hover > a {
	color: var(--bg-primary);
}

.ps-sidebar > ul > li.active {
	background-color: var(--fg-c6);
	color: var(--bg-primary);
}

.ps-sidebar > ul > li.active > div > ul > li,
.ps-sidebar > ul > li.active > a {
	background-color: transparent;
	color: var(--bg-primary);
}

.ps-sidebar > ul > li.active > div > ul > li.active,
.ps-sidebar > ul > li.active > div > ul > li.active > a,
.ps-sidebar > ul > li.active > div > ul > li:hover {
	background-color: var(--bg-c3);
	color: var(--bg-primary);
}

.ps-sidebar-content {
	background-color: var(--fg-c6);
}

.ps-sidebar-left.no-border {
	border: none;
	padding-top: 1.7em;
}

.ps-sidebar-left {
	border-left: 4px solid var(--fg-c6);
}

.ps-sidebar-left > ul > li {
	border-left: none;
}


/* BUTTONS */
.ps-btn-warning, .ps-btn-default {
	color: var(--text-c5);
	border-radius: 0;
}

.ps-btn-success, .ps-btn-info, .ps-btn-danger, .ps-btn-primary {
	color: white;
	border-radius: 0;
}

.ps-btn-default, .ps-btn {
	background-color: var(--bg-c4);
	color: white;
	border-radius: 0;
}

.ps-btn-group button:last-child {
	border-radius: 0;
}

.ps-btn-group button:first-child {
	border-radius: 0;
}


/* NAV PILLS */
.ps-nav-pill li.active {
	background-color: var(--bg-primary);
	color: white;
}

.ps-nav-pill li {
	color: white;
}

.ps-nav-pill li span.fad {
	--fa-primary-color: #fafafa;
	--fa-secondary-color: #000000;
}

.ps-nav-pill {
	border-bottom: 5px solid var(--bg-primary);
}

.ps-nav-pill li.active:hover {
	background-color: var(--bg-primary);
}

.ps-nav-pill li:hover {
	filter: brightness(1);
}

/* INPUTS */
.ps-form-control {
	border-radius: 0;
}

fieldset.ps-form-group {
	border: none;
	padding: 0;
}

.ps-input {
	border-radius: 0;
}

.ps-date-input-group div {
	border-radius: 0;
}