:root.theme-scs-dark {
	--fg-c1: #d9dbe0;
	--bg-c1: #282828;
	--text-c1: #dcdcdc;

	--fg-c2: #7d56a1;
	--bg-c2: #38393c;
	--text-c2: #b3b8bd;

	--fg-c3: #3c7fd5;
	--bg-c3: #151515;
	--text-c3: #e7e7e7;

	--fg-c4: #151515;
	--bg-c4: #383838;
	--text-c4: #eeeeee;

	--fg-c5: #b0b0b0;
	--bg-c5: #c9c9c9;
	--text-c5: #424242;

	--fg-c6: #111111;
	--bg-c6: #181818;

	--border-1: #bebebe;
	--border-2: #bebebe;
	--border-3: #bebebe;
	--border-4: #bebebe;

	--bg-shadow: rgb(0 0 0 / 95%);

	--fg-warning: #ecb51f;
	--fg-danger: #ee2a27;
	--fg-success: #50ba69;
	--fg-default: #fff;
	--fg-primary: #2bace2;

	--bg-warning: #ecb51f;
	--bg-danger: #ee2a27;
	--bg-success: #50ba69;
	--bg-default: #38393c;
	--bg-primary: #2bace2;

	--fa-primary-color: #26c4f4;
	--fa-secondary-color: #26c4f4;

	--font-family: "Lato", sans-serif; 
}
